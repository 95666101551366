import React from 'react'

import 'stylesheets/misc.css'

const MiscPage: React.FunctionComponent = () => (
  <div className='misc-container'>
    <h3>Brb, gonna try to find some miscellaneous things to put here...</h3>
    <p>Did you know that the two hardest things to find are miscellaneous things and fun facts?</p>
  </div>
)

export default MiscPage
